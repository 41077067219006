exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-blog-article-page-index-tsx": () => import("./../../../src/templates/BlogArticlePage/index.tsx" /* webpackChunkName: "component---src-templates-blog-article-page-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-golf-page-index-tsx": () => import("./../../../src/templates/GolfPage/index.tsx" /* webpackChunkName: "component---src-templates-golf-page-index-tsx" */),
  "component---src-templates-group-page-index-tsx": () => import("./../../../src/templates/GroupPage/index.tsx" /* webpackChunkName: "component---src-templates-group-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-hotels-page-index-tsx": () => import("./../../../src/templates/HotelsPage/index.tsx" /* webpackChunkName: "component---src-templates-hotels-page-index-tsx" */),
  "component---src-templates-media-page-index-tsx": () => import("./../../../src/templates/MediaPage/index.tsx" /* webpackChunkName: "component---src-templates-media-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-partners-page-index-tsx": () => import("./../../../src/templates/PartnersPage/index.tsx" /* webpackChunkName: "component---src-templates-partners-page-index-tsx" */),
  "component---src-templates-press-room-page-index-tsx": () => import("./../../../src/templates/PressRoomPage/index.tsx" /* webpackChunkName: "component---src-templates-press-room-page-index-tsx" */),
  "component---src-templates-quotation-page-index-tsx": () => import("./../../../src/templates/QuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-quotation-page-index-tsx" */),
  "component---src-templates-wedding-page-index-tsx": () => import("./../../../src/templates/WeddingPage/index.tsx" /* webpackChunkName: "component---src-templates-wedding-page-index-tsx" */),
  "component---src-templates-wellness-page-index-tsx": () => import("./../../../src/templates/WellnessPage/index.tsx" /* webpackChunkName: "component---src-templates-wellness-page-index-tsx" */),
  "component---src-templates-works-page-index-tsx": () => import("./../../../src/templates/WorksPage/index.tsx" /* webpackChunkName: "component---src-templates-works-page-index-tsx" */)
}

