import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F5F4F4',
      neutralLight2: '#EDE9E5',
      neutralLight1: '#E0DCD7',
      neutralLight0: '#D6CDC4',
      neutralDark4: '#6C6C6C',
      neutralDark3: '#4E4D4D',
      neutralDark2: '#14161E',
      neutralDark1: '#000000',
      primaryDark: '#00435A',
      primaryLight2: '#045571',
      primaryLight1: '#004E68',
      secondaryDark: '#98775A',
      secondaryLight: '#B28E6D',
      dangerLight: '#CC2121',
    },
  },
  fontFamily: {
    heading: "'Cinzel', serif",
    paragraph: "'Poppins', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
